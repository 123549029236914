import Image from "next/image";
import Link from "next/link";
import React from "react";
import { FlexRow } from "src/components/ui/common";
import { STORES_RATING_BLOCK } from "src/constants/dynamic";
import { useAppSelector } from "src/hooks";
import { dynamicStateFieldSelector } from "src/redux/slices";
import { colors } from "src/theme";
import { Caption } from "src/theme/typography";
import { IDynamicStoresRating } from "src/types/dynamic/home/storesRating";
import { ReviewContainerLink, ReviewsContainer } from "./Reviews.styles";

const formatRatingValue = (rawCount: number) => {
  if (rawCount > 999) {
    return `${(rawCount / 1000).toFixed(1)}k`;
  }
  if (rawCount > 200) {
    return `${Math.floor(rawCount / 50) * 50}+`;
  }
  if (rawCount > 100) {
    return `${Math.floor(rawCount / 25) * 25}+`;
  }
  return `${Math.floor(rawCount / 5) * 5}+`;
};

const renderStars = (score: number) => {
  let final = [];

  for (let i = 0; i < Math.round(score); i++) {
    final.push(<img src="/icons/star-full-white.svg" />);
  }

  return final;
};

export const Reviews = () => {
  const storesRating = useAppSelector(
    dynamicStateFieldSelector<IDynamicStoresRating>(STORES_RATING_BLOCK),
  );

  const reviewsData = [
    {
      id: "apple",
      name: "App Store",
      logo: "/reviews/app-store.svg",
      count: storesRating.applestore_count,
      score: storesRating.appstore_score,
      link: "https://apps.apple.com/gb/app/petsapp/id1485414116",
    },
    {
      id: "google",
      name: "Play Store",
      logo: "/reviews/google-play-store.svg",
      count: storesRating.google_count,
      score: storesRating.google_score,
      link: "https://play.google.com/store/apps/details?id=com.gula.petsapp",
    },
    {
      id: "capterra",
      logo: "/reviews/capterra-logo.svg",
      count: 36,
      score: 4.8,
      link: "https://www.capterra.com/p/246622/PetsApp/",
    },
  ];

  return (
    <ReviewsContainer>
      {reviewsData.map(({ id, logo, name, count, score, link }) => {
        const ratingsValue = formatRatingValue(count);

        return (
          <ReviewContainerLink key={id} href={link} target="_blank" rel="noreferrer">
            <img src={logo} />
            {name && (
              <Caption
                textColor={colors.white}
                style={{ whiteSpace: "pre", pointerEvents: "none" }}
              >
                {name}
              </Caption>
            )}
            <FlexRow gap="1px">{renderStars(score)}</FlexRow>
            <Caption
              textColor={colors.mainLight}
              style={{ whiteSpace: "pre", pointerEvents: "none" }}
            >
              {ratingsValue} ratings
            </Caption>
          </ReviewContainerLink>
        );
      })}
    </ReviewsContainer>
  );
};
